import { createContext, useEffect, useReducer, useState } from "react";
import ReducerPackages from "./reducers/ReducerPackages";
import ReducerRequestPackages from "./reducers/ReducerRequestPackages";
import ReducerSharedList from "./reducers/ReducerSharedList";
import ReducerContactList from "./reducers/ReducerContactList";
import ReducerFormPatientInfo from "./reducers/ReducerFormPatientInfo";
import ReducerFormPatientInfoSections from "./reducers/ReducerFormPatientInfoSections";
import ReducerSession from "./reducers/ReducerSession";
import ReducerActivePage from "./reducers/ReducerActivePage";
import ReducerRequests from "./reducers/ReducerRequests";
import ReducerFeedback from "./reducers/ReducerFeedback";
import ReducerExcel from "./reducers/ReducerExcel";
import ReducerExcelFile from "./reducers/ReducerExcelFile";
import { Dropbox } from "dropbox";
import { useCookies } from "react-cookie";
import { createBrowserHistory } from "history";
import api from "./HttpHelper";
import { Buffer } from "buffer";

export const ContextGlobal = createContext();

const ContextGlobalProvider = (props) => {
    const [cookies, setCookies, removeCookies] = useCookies();

    // state
    const [suggestedContactList, setSuggestedContactList] = useState([]);
    const [tempRequests, setTempRequests] = useState([]);
    const [currentRequest, setCurrentRequest] = useState({}); // should be a reducer?
    const [packageLoading, setPackageLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [sessionLoading, setSessionLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState({});
    const [requestLoading, setRequestLoading] = useState(false);

    // reducers
    const [packages, dispatchPackages] = useReducer(ReducerPackages, []);
    const [requestPackages, dispatchRequestPackages] = useReducer(ReducerRequestPackages, []);
    const [sharedList, dispatchSharedList] = useReducer(ReducerSharedList, []);
    const [contactList, dispatchContactList] = useReducer(ReducerContactList, []);
    const [requests, dispatchRequests] = useReducer(ReducerRequests, []);
    const [feedback, dispatchFeedback] = useReducer(ReducerFeedback, []);
    const [excelObj, dispatchExcelObj] = useReducer(ReducerExcel, []);
    const [excelFile, dispatchExcelFile] = useReducer(ReducerExcelFile, []);
    const [formPatientInfo, dispatchFormPatientInfo] = useReducer(ReducerFormPatientInfo, {});
    const [formPatientInfoSections, dispatchFormPatientInfoSections] = useReducer(ReducerFormPatientInfoSections, {
        requestTitle: false,
        patientInfo: false,
        yourInfo: false,
        sharedList: false,
    });
    const [session, dispatchSession] = useReducer(ReducerSession, {});
    const [activePage, dispatchActivePage] = useReducer(ReducerActivePage, {});

    useEffect(() => {
        async function setRequests() {
            if (session.email !== undefined) {
                setRequestLoading(true);
                const requestResponse = await api("get", "/request");

                if (requestResponse?.success != false) {
                    const payload = requestResponse;
                    setTempRequests(payload);
                    dispatchRequests({ type: "SET_REQUESTS", payload });
                    setRequestLoading(false);
                } else if (requestResponse?.status_code == 404) {
                    createBrowserHistory().push("/404");
                    window.location.reload();
                } else if (requestResponse?.status_code == 500) {
                    createBrowserHistory().push("/error");
                    window.location.reload();
                } else {
                    // any other error code...
                    setFeedback({
                        type: "error",
                        heading: "ERROR",
                        message: "Unable to retrieve reports, please refresh page",
                    });
                }

                updateFormPatientInfo({
                    userFirstName: session.firstName,
                    userLastName: session.lastName,
                    email: session.email,
                    phoneNumber: session.phone,
                    lawFirm: session.lawFirm,
                });
            }
        }

        setRequests();
    }, [session]);

    const addSuggestedContact = async (id) => {
        addToContactList(suggestedContactList.find((contact) => contact.userID === id));
    };

    const setActivePage = (page) => {
        dispatchActivePage({ type: "SET_PAGE", payload: { [page]: true } });
    };

    const signup = async (data) => {
        data.isGuest = 0;
        data.referredBy = session.promoCode ? session.promoCode : null;
        const res = await api("put", "/user", { user: data });

        if (res?.success != false) {
            await login({ email: data.email, password: data.password });
        } else if (res?.status_code == 400) {
            return false;
        } else if (res?.status_code == 404) {
            createBrowserHistory().push("/404");
            window.location.reload();
        } else if (res?.status_code == 500) {
            createBrowserHistory().push("/error");
            window.location.reload();
        } else {
            // any other error code...
            setFeedback({
                type: "error",
                heading: "ERROR",
                message: "Unable to create account at this time",
            });
        }

        return true;
    };

    const checkSession = async (login) => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        var emailCookie;

        if (cookies.email == undefined && document.cookie.length !== 0) {
            const found = document.cookie.split("; ").find((row) => row.startsWith("email"));
            if (found) {
                emailCookie = decodeURIComponent(found.split("=")[1]);
            }
        } else {
            emailCookie = decodeURIComponent(cookies.email);
        }

        if (cookies.access_token) {
            dispatchSession({ type: "SET_SESSION", payload: {} });
        }

        if ((cookies.access_token && cookies.email) || login) {
            let payload = {};
            setSessionLoading(true);

            const userResponse = await api("get", "/user", {
                id: emailCookie,
            });

            if (userResponse?.success != false && isNaN(userResponse?.user.email)) {
                payload = userResponse.user;
                payload.isSignedEula = true;
                if (params.referral_token) {
                    payload.promoCode = params.referral_token;
                }
                dispatchSession({ type: "SET_SESSION", payload });

                payload = userResponse.contact;
                dispatchContactList({ type: "SET_LIST", payload });
                const list = userResponse.suggested_contact.filter((contact) => contact.userID != userResponse.user.userID);
                setSuggestedContactList(list);
                setSessionLoading(false);

                return true;
            } else if (userResponse?.status_code == 500) {
                createBrowserHistory().push("/error");
                window.location.reload();
            } else {
                // any other error code...besides 401, that's for guests
                if (userResponse?.status_code != 401) {
                    setFeedback({
                        type: "error",
                        heading: "ERROR",
                        message: "Unable to get account info at this time",
                    });
                }
                // else {
                //     // clear session data and cookies for guest?
                //     logout();
                // }
            }

            setSessionLoading(false);

            return false;
        }
    };

    const login = async (credentials) => {

        if (session?.requestID) {
            credentials.requestID = session?.requestID;
        }

        const clientTokenResponse = await api("post", "/auth", credentials);

        if (clientTokenResponse?.success != false) {
            setCookies("access_token", clientTokenResponse.token);
            setCookies("email", clientTokenResponse.email);

            if (await checkSession(true)) {
                if (currentRequest.request?.requestID) {
                    await api("post", "/request/" + currentRequest.request.requestID, {
                        request: {},
                    });

                    updateFormPatientInfo({
                        userFirstName: session.firstName,
                        userLastName: session.lastName,
                        email: session.email,
                        phoneNumber: session.phone,
                        lawFirm: session.lawFirm,
                    });
                }

                return true;
            }
        }
        if (clientTokenResponse?.status_code == 404) {
            createBrowserHistory().push("/404");
            window.location.reload();
        }
        if (clientTokenResponse?.status_code == 500) {
            createBrowserHistory().push("/error");
            window.location.reload();
        }
        // else {
        //     // any other error code...
        //     setFeedback({
        //         type: "error",
        //         heading: "ERROR",
        //         message: "Unable to log into account at this time",
        //     });
        // }
        return false;
    };

    const verifyEmail = async (credentials) => {
        const clientTokenResponse = await api("post", "/auth", credentials);

        if (clientTokenResponse?.success != false) {
            return {
                isGuest: clientTokenResponse?.auth?.isGuest,
                success: true,
            };
        } else if (clientTokenResponse?.status_code == 404) {
            createBrowserHistory().push("/404");
            window.location.reload();
        } else if (clientTokenResponse?.status_code == 500) {
            createBrowserHistory().push("/error");
            window.location.reload();
        } else {
            return {
                success: false,
            };
        }
    };

    const logout = () => {
        removeCookies("access_token");
        removeCookies("email");
        dispatchSession({ type: "CLEAR_SESSION" });
        // clear the state
        clearState();
        createBrowserHistory().push("/");
        window.location.reload();
    };

    const removeSession = () => {
        removeCookies("access_token");
        removeCookies("email");
        dispatchSession({ type: "CLEAR_SESSION" });
        // clear the state
        clearState();
        window.location.reload();
    };

    const signEula = async (userID) => {
        const value = 1;
        await api("patch", "/user", {
            id: userID,
            field: "consentToEula",
            value: value,
        });

        dispatchSession({
            type: "SET_SESSION",
            payload: { isSignedEula: true, ...session },
        });
    };

    const loginSignEula = async (userID) => {
        const value = 1;
        const userResponse = await api("patch", "/user", {
            id: userID,
            field: "consentToEula",
            value: value,
        });

        dispatchSession({
            type: "SET_SESSION",
            payload: { ...session },
        });
    };

    const updateUser = async (data) => {
        const userResponse = await api("post", "/user", {
            id: session.userID ? session.userID : session.tempUserID,
            user: data,
        });

        if (userResponse?.success != false) {
            const payload = userResponse.user;
            if (session.promoCode) {
                payload.promoCode = session.promoCode;
            }
            dispatchSession({ type: "SET_SESSION", payload });

            return userResponse.user;
        } else if (userResponse?.status_code == 404) {
            createBrowserHistory().push("/404");
            window.location.reload();
        } else if (userResponse?.status_code == 500) {
            createBrowserHistory().push("/error");
            window.location.reload();
        } else {
            // any other error code...
            setFeedback({
                type: "error",
                heading: "ERROR",
                message: "Unable to update account data at this time",
            });
        }
    };

    const searchPackages = async (terms) => {
        setSearchLoading(true);
        dispatchPackages({ type: "SET_PACKAGES", payload: [] });

        if (terms) {
            const searchResponse = await api("get", "/package", { terms });

            if (searchResponse?.success != false) {
                // should an empty _embedded return an empty array instead of an empty object?
                let payload = [];

                if (Object.keys(searchResponse).length !== 0) {
                    payload = searchResponse;
                }

                dispatchPackages({ type: "SET_PACKAGES", payload });
            } else if (searchResponse?.status_code == 404) {
                createBrowserHistory().push("/404");
                window.location.reload();
            } else if (searchResponse?.status_code == 500) {
                createBrowserHistory().push("/error");
                window.location.reload();
            } else {
                // any other error code...
                setFeedback({
                    type: "error",
                    heading: "ERROR",
                    message: "Unable to search for medical procedures data at this time",
                });
            }
        }
        setSearchLoading(false);
    };

    const createRequest = async (productSlug) => {
        if (!cookies.access_token) {
            const clientTokenResponse = await api("post", "/auth", {});

            if (clientTokenResponse?.success != false) {
                setCookies("access_token", clientTokenResponse.token);
            } else if (clientTokenResponse?.status_code == 404) {
                createBrowserHistory().push("/404");
                window.location.reload();
            } else if (clientTokenResponse?.status_code == 500) {
                createBrowserHistory().push("/error");
                window.location.reload();
            } else {
                // any other error code...
                setFeedback({
                    type: "error",
                    heading: "ERROR",
                    message: "Unable to create report at this time", // cookies disabled on site?
                });
            }
        }

        let res = await api("put", "/request", {
            type_product: { slug: productSlug },
            request: { typeProduct: productSlug },
        });

        if (res?.success != false) {
            setCurrentRequest(res);

            const dbTokenRes = await api("get", "/dropboxToken", {});

            if (session.email === undefined) {
                dispatchSession({
                    type: "SET_SESSION",
                    payload: { tempUserID: res.token_info.id, requestID: res.request.requestID, dropboxToken: dbTokenRes.dropboxToken },
                });
            } else {
                dispatchSession({
                    type: "SET_SESSION",
                    payload: { ...session, dropboxToken: dbTokenRes.dropboxToken },
                });
            }

            return res.request.requestID;
        } else if (res?.status_code == 404) {
            createBrowserHistory().push("/404");
            window.location.reload();
        } else if (res?.status_code == 500) {
            createBrowserHistory().push("/error");
            window.location.reload();
        } else {
            // any other error code...
            setFeedback({
                type: "error",
                heading: "ERROR",
                message: "Unable to create report at this time",
            });
        }
    };

    const loadRequest = async (id) => {
        dispatchPackages({ type: "CLEAR_ALL" });
        dispatchRequestPackages({ type: "CLEAR_ALL" });
        dispatchSharedList({ type: "CLEAR_ALL" });
        dispatchFormPatientInfo({ type: "CLEAR_ALL" });

        const res = await api("get", "/request/" + id);

        if (res?.success != false) {
            const dbTokenRes = await api("get", "/dropboxToken", {});
            // console.log(res.data._embedded);
            setCurrentRequest(res);
            var packages = res.request_package;

            packages = packages.map((p) => {
                return {
                    request_package: p,
                    request_bill: res.request_bill.filter((bill) => bill.requestPackageID === p.requestPackageID),
                };
            });
            // console.log(packages);
            dispatchRequestPackages({
                type: "ADD_PACKAGES",
                payload: packages,
            });
            dispatchFormPatientInfo({
                type: "UPDATE_FORM",
                payload: {
                    reportTitle: res.request.customNote,
                    dueDate: res.request.dueDate?.replaceAll("-", "/"),
                    location: res.patient?.address,
                    firstName: res.patient?.firstName,
                    lastName: res.patient?.lastName,
                    dateOfLoss: res.request?.dateOfLoss?.replaceAll("-", "/"),
                    sex: res.patient?.sex,
                    latitude: res.patient?.latitude,
                    longitude: res.patient?.longitude,
                    lawFirm: res.user?.lawFirm,
                    userFirstName: res.user?.firstName,
                    userLastName: res.user?.lastName,
                    phoneNumber: res.user?.phone,
                    email: res.user?.email,
                },
            });

            dispatchSharedList({
                type: "ADD_TO_SHARED_LIST",
                payload: res.request_subscription,
            });

            dispatchSession({
                type: "SET_SESSION",
                payload: { ...session, dropboxToken: dbTokenRes.dropboxToken },
            });

            return res;
        } else if (res?.status_code == 404) {
            createBrowserHistory().push("/404");
            window.location.reload();
        } else if (res?.status_code == 500) {
            createBrowserHistory().push("/error");
            window.location.reload();
        } else {
            // any other error code...
            setFeedback({
                type: "error",
                heading: "ERROR",
                message: "Unable to load this report at this time",
            });
        }
    };

    const deleteRequest = async (requestID) => {
        const res = await api("delete", "/request/" + requestID);

        if (res?.success != false) {
            dispatchRequests({ type: "DELETE_REQUEST", payload: requestID });
        } else if (res?.status_code == 404) {
            createBrowserHistory().push("/404");
            window.location.reload();
        } else if (res?.status_code == 500) {
            createBrowserHistory().push("/error");
            window.location.reload();
        } else {
            // any other error code...
            setFeedback({
                type: "error",
                heading: "ERROR",
                message: "Unable to delete report at this time",
            });
        }
    };

    const uploadFiles = async (file) => {
        var uploadResponse = {};
        var repeat = 1;
        var updatedToken = "";
        do {
            repeat--;
            try {

                const dbx = new Dropbox({ accessToken: (updatedToken.length == 0) ? session.dropboxToken : updatedToken, pathRoot: JSON.stringify({ ".tag": "root", root: "11531746401" }) });

                var progress = 0;
                var res;
                setUploadProgress({
                    progress: 0,
                    name: file.path,
                });
        
                if (file.size < 4194304) {
                    progress = 100;
                    setUploadProgress({
                        progress: Math.round(progress),
                        name: file.path,
                    });
        
                    res = await dbx.filesUpload({
                        path: "/AM Access/" + currentRequest.request.requestID + " - " + new Date().toLocaleString().split(",")[0].replaceAll("/", "-") + "/" + file.name,
                        contents: file,
                    });
                    // console.log(currentRequest);
                } else {
                    var chunks = [];
                    var offset = 0;
                    var sessionId;
        
                    while (offset < file.size) {
                        var chunkSize = Math.min(4194304, file.size - offset);
                        chunks.push(file.slice(offset, offset + chunkSize));
                        offset += chunkSize;
                    }
        
                    for (const [index, chunk] of chunks.entries()) {
                        if (index == 0) {
                            sessionId = (
                                await dbx.filesUploadSessionStart({
                                    close: false,
                                    contents: chunk,
                                })
                            ).result.session_id;
                        } else if (index < chunks.length - 1) {
                            var cursor = {
                                session_id: sessionId,
                                offset: index * 4194304,
                            };
        
                            await dbx.filesUploadSessionAppendV2({
                                cursor,
                                close: false,
                                contents: chunk,
                            });
                        } else {
                            var cursor = {
                                session_id: sessionId,
                                offset: file.size - chunk.size,
                            };
                            var commit = {
                                path: "/AM Access/" + currentRequest.request.requestID + " - " + new Date().toLocaleString().split(",")[0].replaceAll("/", "-") + "/" + file.name,
                            };
                            res = await dbx.filesUploadSessionFinish({
                                cursor,
                                commit,
                                contents: chunk,
                            });
                        }
        
                        progress += 100 / chunks.length;
                        setUploadProgress({
                            progress: Math.round(progress),
                            name: file.path,
                        });
                    }
                }
        
                // setUploadProgress({});
        
                const clientRes = await api("post", "/request/" + currentRequest.request.requestID, {
                    request: {},
                    request_attachment: {
                        name: res.result.name,
                        link: res.result.path_display,
                    },
                });
        
                if (clientRes?.success != false) {
                    repeat = -1; // leave the do while loop
                    setCurrentRequest(clientRes);
                    setUploadProgress({});
                    uploadResponse =  {
                        success: true
                    };
                } else {
                    repeat = -1; // leave the do while loop
                    setFeedback({
                        type: "error",
                        heading: "ERROR",
                        message: "There was an error uploading " + file.name + ". Please try again",
                    });
                    uploadResponse =  {
                        file: file,
                        success: false,
                    };
                }

            } catch (error) {
                // Dropbox token validation error - get a new token
                if (error.status == 401) {
                    const dbTokenRes = await api("get", "/refreshedDropboxToken", {});
                    updatedToken = dbTokenRes.dropboxToken;
                } else {
                    setFeedback({
                        type: "error",
                        heading: "ERROR",
                        message: "There was an error uploading " + file.name + ". Please try again",
                    });
                    uploadResponse = {
                        file: file,
                        success: false,
                    };
                }
            }
        } while (repeat >= 0);

        if (updatedToken.length > 0) {
            // just update the session with the new token ONCE
            dispatchSession({
                type: "SET_SESSION",
                payload: { ...session, dropboxToken: updatedToken },
            });
        }
        return uploadResponse;
    };

    const deleteFile = async (path) => {
        const dbx = new Dropbox({ accessToken: session.dropboxToken, pathRoot: JSON.stringify({ ".tag": "root", root: "11531746401" }) });

        await dbx.filesDeleteV2({
            path: "/AM Access/" + currentRequest.request.requestID + " - " + new Date().toLocaleString().split(",")[0].replaceAll("/", "-") + "/" + path,
        });
    };

    const downloadFiles = async (file) => {
        const dbx = new Dropbox({ accessToken: session.dropboxToken });
        const res = await dbx.filesDownload({ path: file.link });
        const url = window.URL.createObjectURL(res.result.fileBlob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", res.result.name);
        link.click();
    };

    const downloadS3 = async (id = null) => {
        var link, name;

        if (id) {
            const request = await loadRequest(id);
            link = request.document[0].link.split("/")[1];
            name = request.document[0].name;
        } else {
            link = currentRequest.document[0].link.split("/")[1];
            name = currentRequest.document[0].name;
        }

        const res = await api("get", "/document/" + link, {});

        if (res?.document?.file) {
            const blob = new Blob([Buffer.from(res.document?.file, "base64")]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", name + ".pdf");
            link.click();
        } else if (res?.status_code == 404) {
            createBrowserHistory().push("/404");
            window.location.reload();
        } else if (res?.status_code == 500) {
            createBrowserHistory().push("/error");
            window.location.reload();
        } else {
            // any other error code...
            setFeedback({
                type: "error",
                heading: "ERROR",
                message: "Unable to download documents at this time",
            });
        }

        if (id) {
            clearState();
        }
    };

    const downloadMultipleS3 = async (files) => {
        if (files.length != 0) {
            files.map(async (file) => {
                const link = file.link.split("/")[1];

                const res = await api("get", "/document/" + link, {});

                if (res?.document?.file) {
                    const blob = new Blob([Buffer.from(res?.document?.file, "base64")]);
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", file.name + ".pdf");
                    link.click();
                } else if (res?.status_code == 404) {
                    createBrowserHistory().push("/404");
                    window.location.reload();
                } else if (res?.status_code == 500) {
                    createBrowserHistory().push("/error");
                    window.location.reload();
                } else {
                    // any other error code...
                    setFeedback({
                        type: "error",
                        heading: "ERROR",
                        message: "Unable to download documents at this time",
                    });
                }
            });
        }
    };

    const dateOfLoss = async (date) => {
        // DOS sometimes comes in as "yyyy/mm/dd" for some reason...
        if (date.split("/")[0].length == 4) {
            return new Date(formPatientInfo.dateOfLoss?.split("/")[0], parseInt(formPatientInfo.dateOfLoss?.split("/")[1]) - 1, parseInt(formPatientInfo.dateOfLoss?.split("/")[2])).toISOString();
        } else {
            // "dd/mm/yyyy"
            return new Date(formPatientInfo.dateOfLoss?.split("/")[2], parseInt(formPatientInfo.dateOfLoss?.split("/")[0]) - 1, parseInt(formPatientInfo.dateOfLoss?.split("/")[1])).toISOString();
        }
    };

    const updateRequest = async (isSubmitted, singlePageProduct) => {
        let userID;

        if (session.email === undefined) {
            // pre submission check email
            if ((formPatientInfo.email && !isSubmitted) || (formPatientInfo.email && singlePageProduct)) {
                const verify = await api("post", "/auth", {
                    email: formPatientInfo.email,
                });
                // email exists, return userID for insertion into request body, but do not update session for guest user w/ verified email data!
                if (verify?.status_code != 401) {
                    userID = verify.auth?.id;

                    dispatchSession({
                        type: "SET_SESSION",
                        payload: { ...session, requestUserID: userID },
                    });
                }
            }

            // only update session data if request submitted not on someone's behalf
            // if both temp and userID are present == for someone else
            // else if singlePageProduct and userID is undefined == guest
            if ((isSubmitted && session.tempUserID && session.requestUserID == undefined && !singlePageProduct) || (isSubmitted && singlePageProduct && userID == undefined)) {
                const specialToken = await api("post", "/auth", {
                    user: {
                        lawFirm: formPatientInfo.lawFirm,
                        phone: formPatientInfo.phoneNumber,
                        firstName: formPatientInfo.userFirstName,
                        lastName: formPatientInfo.userLastName,
                        email: formPatientInfo.email,
                        referredBy: session.promoCode ? session.promoCode : null,
                    },
                });

                if (specialToken?.success != false) {
                    dispatchSession({
                        type: "SET_SESSION",
                        payload: {
                            tempUserID: specialToken.user.userID,
                            tempEmail: specialToken.user.email,
                            isConfirmed: specialToken.user.isConfirmed,
                            isGuest: specialToken.user.isGuest,
                            isSignedEula: true,
                            promoCode: session.promoCode ? session.promoCode : specialToken.user.referredBy,
                        },
                    });
                    setCookies("access_token", specialToken.token);
                    // not sure if we need to verify email again...
                } else if (specialToken?.success == false) {
                    return false;
                } else if (specialToken?.status_code == 404) {
                    createBrowserHistory().push("/404");
                    window.location.reload();
                } else if (specialToken?.status_code == 500) {
                    createBrowserHistory().push("/error");
                    window.location.reload();
                } else {
                    // any other error code...
                    setFeedback({
                        type: "error",
                        heading: "ERROR",
                        message: "Unable to save your info at this time",
                    });
                    return false;
                }
            }
        }

        let res = await api("post", "/request/" + currentRequest.request.requestID, {
            request: {
                /**
                 *  if signed in, use session.userID
                 *  if guest, submit for someone else? use userID or session.requestUserID
                 *  if for self (guest) use session.tempUserID
                 */

                userID: session.userID
                    ? session.userID // if signed in, use session.userID
                    : userID
                    ? userID
                    : session.requestUserID
                    ? session.requestUserID // if guest, submit for someone else? use userID or session.requestUserID
                    : session.tempUserID, // if for self (guest) use session.tempUserID
                customNote: formPatientInfo.reportTitle,
                accessClientComment: formPatientInfo.comment,
                dueDate: formPatientInfo.dueDate ? new Date(formPatientInfo.dueDate?.split("/")[2], parseInt(formPatientInfo.dueDate?.split("/")[0]) - 1, parseInt(formPatientInfo.dueDate?.split("/")[1])).toISOString() : undefined,
                requestStatusID: isSubmitted ? 2 : undefined,
                disclosureDate: formPatientInfo.disclosureDate ? new Date(formPatientInfo.disclosureDate?.split("/")[2], parseInt(formPatientInfo.disclosureDate?.split("/")[0]) - 1, parseInt(formPatientInfo.disclosureDate?.split("/")[1])).toISOString() : undefined,
                isRush: formPatientInfo.isRush ? 1 : 0,
                isTrial: formPatientInfo.isTrial ? 1 : 0,
                dateOfLoss: await dateOfLoss(formPatientInfo.dateOfLoss),
                promoCode: session.promoCode ? session.promoCode : session.referredBy ? session.referredBy : formPatientInfo.promoCode,
                billsToAnalyze: formPatientInfo.billsToAnalyze ? formPatientInfo.billsToAnalyze : "",
            },
            patient: {
                address: formPatientInfo.location,
                firstName: formPatientInfo.firstName,
                lastName: formPatientInfo.lastName,
                sex: formPatientInfo.sex,
                latitude: formPatientInfo.latitude,
                longitude: formPatientInfo.longitude,
                city: formPatientInfo.city,
                state: formPatientInfo.state,
                zip: formPatientInfo.zip,
            },
        });

        if (res?.success != false) {
            setCurrentRequest(res);

            // clear out excel object when submitting
            if (isSubmitted) {
                dispatchExcelObj({ type: "CLEAR_EXCEL" });
            }

            return res.request.requestID;
        } else if (res?.success == false) {
            return false;
        } else if (res?.status_code == 404) {
            createBrowserHistory().push("/404");
            window.location.reload();
        } else if (res?.status_code == 500) {
            createBrowserHistory().push("/error");
            window.location.reload();
        } else {
            // any other error code...
            setFeedback({
                type: "error",
                heading: "ERROR",
                message: "Unable to update report at this time",
            });
            return false;
        }

        return true;
    };

    const patchRequest = async (id, field, value) => {
        const res = await api("patch", "/request", {
            id: id,
            field: field,
            value: value,
        });

        if (res?.success != false) {
            dispatchRequests({
                type: "PATCH_REQUEST",
                payload: res,
            });
            setCurrentRequest(res);
        } else if (res?.status_code == 404) {
            createBrowserHistory().push("/404");
            window.location.reload();
        } else if (res?.status_code == 500) {
            createBrowserHistory().push("/error");
            window.location.reload();
        } else {
            // any other error code...
            setFeedback({
                type: "error",
                heading: "ERROR",
                message: "Unable to update report at this time",
            });
            return false;
        }
    };

    const searchRequests = async (terms) => {
        // filter on temp requests array
        const payload = tempRequests.filter((requestRow) => {
            if (requestRow.patient !== undefined && requestRow.patient !== null) {
                return requestRow.patient.firstName.match(new RegExp(terms, "i")) || requestRow.patient.lastName.match(new RegExp(terms, "i"));
            }

            return false;
        });
        // setRequests(payload);
        dispatchRequests({ type: "SET_REQUESTS", payload });
    };

    const contactUs = async (data) => {
        const res = await api("put", "/message", {
            message: data,
        });

        if (res?.success != false) {
            return true;
        } else if (res?.status_code == 404) {
            createBrowserHistory().push("/404");
            window.location.reload();
        } else if (res?.status_code == 500) {
            createBrowserHistory().push("/error");
            window.location.reload();
        } else {
            // any other error code...
            setFeedback({
                type: "error",
                heading: "ERROR",
                message: "Unable to send message at this time",
            });
            return false;
        }
    };

    const downloadSample = async (data) => {
        const res = await api("put", "/message", { message: data });

        if (res?.success != false) {
            return true;
        } else if (res?.status_code == 404) {
            createBrowserHistory().push("/404");
            window.location.reload();
        } else if (res?.status_code == 500) {
            createBrowserHistory().push("/error");
            window.location.reload();
        } else {
            // any other error code...
            setFeedback({
                type: "error",
                heading: "ERROR",
                message: "Unable to send message at this time",
            });
            return false;
        }
    };

    const reachOut = async (data) => {
        console.log(data);
        const res = await api("put", "/message", { message: data });

        if (res?.success != false) {
            return true;
        } else if (res?.status_code == 404) {
            createBrowserHistory().push("/404");
            window.location.reload();
        } else if (res?.status_code == 500) {
            createBrowserHistory().push("/error");
            window.location.reload();
        } else {
            // any other error code...
            setFeedback({
                type: "error",
                heading: "ERROR",
                message: "Unable to send message at this time",
            });
            return false;
        }
    };

    const addRequestPackages = async (packageID) => {
        setPackageLoading(true);
        var requestID;

        if (!currentRequest.request) {
            requestID = await createRequest("type_product_medical_cost_projection_standard");
        } else {
            requestID = currentRequest.request.requestID;
        }

        const res = await api("put", "/request_package", { requestID, packageID });

        if (res?.success != false) {
            dispatchRequestPackages({
                type: "ADD_PACKAGE",
                payload: res,
            });
            setPackageLoading(false);
            return true;
        } else if (res?.status_code == 404) {
            createBrowserHistory().push("/404");
            window.location.reload();
        } else if (res?.status_code == 500) {
            createBrowserHistory().push("/error");
            window.location.reload();
        } else {
            // any other error code...
            setFeedback({
                type: "error",
                heading: "ERROR",
                message: "Unable to add procedure to report at this time",
            });
            setPackageLoading(false);
            return false;
        }
    };

    const updateRequestPackage = async (payload) => {
        const res = await api("post", "/request_package/" + payload.requestPackageID, {
            request_package: payload,
        });

        if (res?.success == false) {
            if (res?.status_code == 404) {
                createBrowserHistory().push("/404");
                window.location.reload();
                // } else if (res?._status?.status_code == 500) {
                //     createBrowserHistory().push("/error");
                //     window.location.reload();
            } else {
                // any other error code...
                setFeedback({
                    type: "error",
                    heading: "ERROR",
                    message: "Cannot update occurance at this time",
                });
                return false;
            }
        }
        payload.updated_request_package = res.request_package;
        payload.api = api;

        dispatchRequestPackages({ type: "UPDATE_PACKAGE", payload });

        if (payload.bill) {
            const res = await api("patch", "/request_bill", {
                id: payload.bill,
                field: "isIncluded",
                value: Number(payload.include),
            });

            if (res?.success !== undefined) {
                if (res?.status_code == 404) {
                    createBrowserHistory().push("/404");
                    window.location.reload();
                } else if (res?.status_code == 500) {
                    createBrowserHistory().push("/error");
                    window.location.reload();
                } else {
                    // any other error code...
                    setFeedback({
                        type: "error",
                        heading: "ERROR",
                        message: "Unable to change bill at this time",
                    });
                    return false;
                }
            }
        }
    };

    const reorderReportPakages = (startIndex, endIndex) => {
        api("patch", "/request_package", {
            id: requestPackages[startIndex].request_package.requestPackageID,
            field: "packageOrder",
            value: endIndex,
        });

        const payload = { startIndex, endIndex };
        dispatchRequestPackages({ type: "REORDER_PACKAGES", payload });
    };

    const deleteReportPackage = (requestPackageID) => {
        api("delete", "/request_package/" + requestPackageID, {});

        const payload = { requestPackageID };
        dispatchRequestPackages({ type: "DELETE_PACKAGE", payload });
    };

    const updateFormPatientInfo = (payload) => {
        // console.log(payload);
        dispatchFormPatientInfo({ type: "UPDATE_FORM", payload });
    };

    const updateFormPatientInfoSections = (payload) => {
        dispatchFormPatientInfoSections({ type: "UPDATE", payload });
    };

    const updateFormContactInfo = (payload) => {
        dispatchSharedList({ type: "UPDATE_CONTACT", payload });
    };

    const addToSharedList = async (data) => {
        for (const contact of data) {
            const res = await api("put", "/request_subscription", {
                request_subscription: {
                    ...contact,
                    requestID: currentRequest.request.requestID,
                    sharedBy: session.email ? session.userID : session.tempUserID,
                },
            });

            if (res?.success != false) {
                const payload = res.request_subscription;
                dispatchSharedList({ type: "ADD_TO_SHARED_LIST", payload });
            } else if (res?.status_code == 404) {
                createBrowserHistory().push("/404");
                window.location.reload();
            } else if (res?.status_code == 500) {
                createBrowserHistory().push("/error");
                window.location.reload();
            } else {
                // any other error code...
                setFeedback({
                    type: "error",
                    heading: "ERROR",
                    message: "Unable to add to share list",
                });
                return false;
            }
        }
    };

    const deleteFromSharedList = (requestSubscriptionID) => {
        api("delete", "/request_subscription/" + requestSubscriptionID, {});

        const payload = { requestSubscriptionID };
        dispatchSharedList({ type: "DELETE_CONTACT", payload });
    };

    const updateFormContact = (payload) => {
        dispatchContactList({ type: "UPDATE_CONTACT", payload });
    };

    const updateContact = async (data) => {
        const contactResponse = await api("post", "/contact/" + data.contactID, {
            contact: data,
        });

        if (contactResponse?.success != false) {
            const payload = contactResponse;
            dispatchContactList({ type: "UPDATE_CONTACT", payload });
        } else if (contactResponse?.status_code == 404) {
            createBrowserHistory().push("/404");
            window.location.reload();
        } else if (contactResponse?.status_code == 500) {
            createBrowserHistory().push("/error");
            window.location.reload();
        } else {
            // any other error code...
            setFeedback({
                type: "error",
                heading: "ERROR",
                message: "Unable to update contact info at this time",
            });
        }
    };

    const addToContactList = async (data) => {
        const contactRes = await api("put", "/contact", { contact: data });

        if (contactRes?.success != false) {
            const payload = contactRes.contact;
            dispatchContactList({ type: "ADD_TO_LIST", payload });
        } else if (contactRes?.success == false) {
            setFeedback({
                type: "warning",
                heading: "WARNING",
                message: "Contact in contact book already",
            });
        } else if (contactRes?.status_code == 404) {
            createBrowserHistory().push("/404");
            window.location.reload();
        } else if (contactRes?.status_code == 500) {
            createBrowserHistory().push("/error");
            window.location.reload();
        } else {
            // any other error code...
            setFeedback({
                type: "error",
                heading: "ERROR",
                message: "Unable to add contact to contact book at this time",
            });
        }
    };

    const deleteFromContactList = async (contactID) => {
        const payload = { contactID };
        dispatchContactList({ type: "DELETE_CONTACT", payload });

        const res = await api("delete", "/contact/" + contactID, {});

        if (res?.success == false) {
            if (res?.status_code == 404) {
                createBrowserHistory().push("/404");
                window.location.reload();
            } else if (res?.status_code == 500) {
                createBrowserHistory().push("/error");
                window.location.reload();
            } else {
                // any other error code...
                setFeedback({
                    type: "error",
                    heading: "ERROR",
                    message: "Unable to delete contact to contact book at this time",
                });
            }
        }
    };

    const clearState = (leaveContacts = false) => {
        // clear the state
        dispatchPackages({ type: "CLEAR_ALL" });
        dispatchRequestPackages({ type: "CLEAR_ALL" });
        dispatchSharedList({ type: "CLEAR_ALL" });
        dispatchFormPatientInfo({ type: "CLEAR_ALL" });

        if (!leaveContacts) {
            dispatchContactList({ type: "CLEAR_ALL" });
        }

        setCurrentRequest({});
    };

    const sendConfirmEmail = async (data) => {
        const confirmRes = await api("put", "/auth/reset", data);

        if (confirmRes?.success != false) {
            return true;
        } else if (confirmRes?.status_code == 404) {
            createBrowserHistory().push("/404");
            window.location.reload();
        } else if (confirmRes?.status_code == 500) {
            createBrowserHistory().push("/error");
            window.location.reload();
        } else {
            // any other error code...
            setFeedback({
                type: "error",
                heading: "ERROR",
                message: "Unable to send confirmation email at this time",
            });
            return false;
        }
    };

    const sendResetEmail = async (email) => {
        const data = {
            email: email.email,
            type: "reset",
        };
        const resetResponse = await api("put", "/auth/reset", data);

        if (resetResponse?.success != false) {
            return true;
        } else if (resetResponse?.status_code == 404) {
            createBrowserHistory().push("/404");
            window.location.reload();
        } else if (resetResponse?.status_code == 500) {
            createBrowserHistory().push("/error");
            window.location.reload();
        } else {
            // any other error code...
            setFeedback({
                type: "error",
                heading: "ERROR",
                message: "Unable to send reset email at this time",
            });
            return false;
        }
    };

    const validateUserToken = async (token) => {
        const validResponse = await api("post", "/auth/reset/token", {
            reset_token: token,
        });

        if (validResponse?.success != false) {
            return true;
        } else if (validResponse?.status_code == 404) {
            createBrowserHistory().push("/404");
            window.location.reload();
        } else if (validResponse?.status_code == 500) {
            createBrowserHistory().push("/error");
            window.location.reload();
        } else {
            // any other error code...
            setFeedback({
                type: "error",
                heading: "ERROR",
                message: "Unable to validate account at this time",
            });
            return false;
        }
    };

    const resetPassword = async (data) => {
        const resetPassword = await api("post", "/auth/reset/update", {
            reset_token: data.token,
            ...data.password,
        });

        // console.log("reset response: ", resetPassword);
        if (resetPassword?.success != false) {
            return true;
        } else if (resetPassword?.status_code == 404) {
            createBrowserHistory().push("/404");
            window.location.reload();
        } else if (resetPassword?.status_code == 500) {
            createBrowserHistory().push("/error");
            window.location.reload();
        } else {
            // any other error code...
            setFeedback({
                type: "error",
                heading: "ERROR",
                message: "Unable to reset password at this time",
            });
            return false;
        }
    };

    const updatePassword = async (data) => {
        const updateResponse = await api("post", "/user", {
            id: session.userID,
            user: data,
        });

        if (updateResponse?.success != false) {
            return true;
        } else if (updateResponse?.status_code == 404) {
            createBrowserHistory().push("/404");
            window.location.reload();
        } else if (updateResponse?.status_code == 500) {
            createBrowserHistory().push("/error");
            window.location.reload();
        } else {
            // any other error code...
            setFeedback({
                type: "error",
                heading: "ERROR",
                message: "Unable to update password at this time",
            });
            return false;
        }
    };

    const confirmEmail = async (data) => {
        const resetPassword = await api("post", "/auth/reset/confirm", data);

        // console.log("reset response: ", resetPassword);
        if (resetPassword?.success != false) {
            return {
                status: true,
                message: "confirmed",
            };
        } else if (resetPassword?.status_code == 401) {
            return {
                status: false,
                message: "unauthorized",
            };
        } else if (resetPassword?.status_code == 404) {
            createBrowserHistory().push("/404");
            window.location.reload();
        } else if (resetPassword?.status_code == 500) {
            createBrowserHistory().push("/error");
            window.location.reload();
        } else {
            // any other error code...
            setFeedback({
                type: "error",
                heading: "ERROR",
                message: "Unable to confirm email at this time",
            });
            return {
                status: false,
                message: "error",
            };
        }
    };

    const setFeedback = async (data) => {
        const payload = data;
        dispatchFeedback({ type: "SET_FEEDBACK", payload });
    };

    const removeFeedback = async (index) => {
        const payload = index;
        dispatchFeedback({ type: "DELETE_FEEDBACK", payload });
    };

    const createCode = async (data) => {
        const codeResponse = await api("put", "/auth/code", { code: data });

        if (codeResponse?.success != false) {
            return true;
        } else if (codeResponse?.status_code == 404) {
            createBrowserHistory().push("/404");
            window.location.reload();
        } else if (codeResponse?.status_code == 500) {
            createBrowserHistory().push("/error");
            window.location.reload();
        } else {
            // any other error code...
            setFeedback({
                type: "error",
                heading: "ERROR",
                message: "Unable to create one time code at this time",
            });
            return false;
        }
    };

    const setPromoCode = async (referral) => {
        const payload = { ...session, promoCode: referral };
        dispatchSession({ type: "SET_SESSION", payload });
    };

    return (
        <ContextGlobal.Provider
            value={{
                packages,
                searchPackages,
                requestPackages,
                addRequestPackages,
                updateRequestPackage,
                formPatientInfo,
                updateFormPatientInfo,
                addToSharedList,
                sharedList,
                clearState,
                reorderReportPakages,
                deleteReportPackage,
                deleteFromSharedList,
                updateFormContactInfo,
                formPatientInfoSections,
                updateFormPatientInfoSections,
                checkSession,
                session,
                login,
                verifyEmail,
                logout,
                removeSession,
                updateUser,
                signEula,
                loginSignEula,
                activePage,
                setActivePage,
                contactList,
                deleteFromContactList,
                updateFormContact,
                updateContact,
                addToContactList,
                suggestedContactList,
                addSuggestedContact,
                requests,
                searchRequests,
                currentRequest,
                packageLoading,
                updateRequest,
                loadRequest,
                patchRequest,
                searchLoading,
                createRequest,
                deleteRequest,
                uploadFiles,
                sessionLoading,
                signup,
                downloadFiles,
                downloadS3,
                downloadMultipleS3,
                contactUs,
                deleteFile,
                sendResetEmail,
                validateUserToken,
                resetPassword,
                updatePassword,
                sendConfirmEmail,
                confirmEmail,
                setFeedback,
                feedback,
                removeFeedback,
                createCode,
                setPromoCode,
                uploadProgress,
                excelObj,
                excelFile,
                requestLoading,
                setRequestLoading,
                downloadSample,
                reachOut,
            }}
        >
            {props.children}
        </ContextGlobal.Provider>
    );
};

export default ContextGlobalProvider;
